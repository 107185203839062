// Site environment (dev/qa/prod...) that will appear on the main page.
// export const env = 'DEV';
export const env = 'QA';

// API host url:
// export const apiHost = 'http://localhost:8080';
export const apiHost = 'https://tkk-api-qa.taiwankk.com';

// AAD ClientID
// export const clientId = 'cf7e14a9-f6d4-45a4-8bdb-7b67efd55745';
export const clientId = '80e01145-4b76-4190-91f5-c5cd6905abd5';